import React, { useState } from "react";
import Button from "../Components/Utl/Button";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    company: "",
    industry: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // Reset form
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      company: "",
      industry: "",
      message: "",
    });
  };

  return (
    <div className="flex flex-col md:mx-20 mx-7 py-10 mb-10 font-body capitalize">
      <h2 className="md:text-desktop_header_32 text-mobile_header_24 font-bold">
        Drop us a line
      </h2>
      <form className="mt-9 md:mb-16" onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              Full Name
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="Enter First Name"
          />
        </div>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              email
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="Enter Email Address"
          />
        </div>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              Phone Number
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="+234 "
          />
        </div>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              company
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="Enter Company Name"
          />
        </div>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              industry
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <input
            type="text"
            id="industry"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="Enter Industry"
          />
        </div>
        <div className="mb-4">
          <div className="flex gap-4 items-center">
            <label htmlFor="fullName" className="text-desktop_bodytext_18">
              Message
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M10 5.55556H6.33333L8.94444 8.16667L8.16667 8.94444L5.55556 6.33333V10H4.44444V6.27778L1.83333 8.88889L1.05556 8.11111L3.55556 5.55556H0V4.44444H3.66667L1.05556 1.83333L1.83333 1.05556L4.44444 3.66667V0H5.55556V3.55556L8.11111 1L8.88889 1.83333L6.27778 4.44444H10V5.55556Z"
                fill="#D22217"
              />
            </svg>
          </div>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
            className="border-b border-primary-400 w-full py-2 focus:outline-none focus:border-primary-500"
            placeholder="Enter Your Messages Here"
          ></textarea>
        </div>
        <a
          className={`text-mobile_buttontext_20 md:text-desktop_buttontext_24 font-medium  capitalize font-body rounded py-4 px-[10px] justify-center align-middle mx-auto my-auto $ flex items-center`}
          href=""
        ></a>
        <div className="flex justify-end">
          <button
            type="submit"
            className="text-mobile_buttontext_20 md:text-desktop_buttontext_24 font-medium capitalize font-body rounded py-4 px-[10px] justify-center items-center bg-primary-500 text-white md:w-1/2 w-10/12"
          >
            send message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
