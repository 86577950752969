import React from "react";

const Product_Card = () => {
  return (
    <>
      <div className="px-8 md:px-20 dark:bg-bg_black bg-white md:pb-16 pb-10">
        <div className="dark:text-white md:pt-16 pt-6 md:pr-64">
          <h1 className="md:text-desktop_header_36 text-mobile_header_24 font-semibold font-sans capitalize">
            our product and services
          </h1>
          <p className="pt-4 md:pt-0 text-mobile_bodytext_16 font-body capitalize">
            At Hala Tech AI, we offer a versatile range of AI-enabled
            applications and services designed to enhance customer experiences
            and streamline operations. Explore our key offerings:
          </p>
        </div>
        <div className="md:mt-20 mt-11 flex flex-col md:flex-row md:gap-10 gap-6">
          <div className="h-auto md:w-1/2 rounded overflow-hidden bg-[#F1F1BE] relative text-black-500">
            <div className="mx-6 mb-6 md:mb-32">
              <h2 className="pt-6 md:text-desktop_bodytext_16 text-mobile_bodytext_14 font-body text-left">
                01
              </h2>
              <h1 className="pt-3 md:text-desktop_header_32 font-sans font-medium text-left md:pr-[89px] text-mobile_header_24 capitalize">
                Hala Tech AI Customer Support as a Service
              </h1>
              <p className="pt-4 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left capitalize">
                We revolutionize the contact center experience by blending
                cutting-edge AI technology with human interaction. With this
                platform, we ensure prompt and efficient customer support across
                multiple communication channels, including company phone lines,
                WhatsApp, WebChat, SMS, Voice, and Email.
              </p>
              <p className="pt-3 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left md:pr-[222px] pr-[100px]">
                Transforming Customer Support with Human-AI Interaction
              </p>
            </div>

            <div className="mt-2 flex absolute bottom-0 right-0 left-56 top-96 md:left-auto md:right-0 md:top-[330px] 2xl:top-60 py-4 justify-center md:justify-end">
              <div className="w-28 md:w-72 h-28 md:h-72 bg-primary-500 rotate-45 rounded-3xl"></div>
              <img
                src={require("../Assets/ezgif 1.png")}
                alt=""
                className="w-24 md:w-[170px] h-24 md:h-[170px] absolute md:left-16 md:right-0 md:top-0"
              />
            </div>
          </div>

          <div className="h-auto md:w-1/2 rounded overflow-hidden bg-[#07111A] relative text-white">
            <div className="mx-6 mb-6 md:mb-0">
              <h2 className="pt-6 md:text-desktop_bodytext_16 text-mobile_bodytext_14 font-body text-left">
                02
              </h2>
              <h1 className="pt-3 md:text-desktop_header_32 font-sans font-medium text-left md:pr-[89px] text-mobile_header_24 capitalize">
                Unleashing the Power of Twilio Flex CPaaS
              </h1>
              <p className="pt-4 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left capitalize">
                We understand the growing demand for expertise in Twilio and
                Twilio Flex CPaaS (Communication Platform as a Service). Our
                consulting and staff augmentation services aim to assist
                enterprise-level companies in supplementing their resources and
                amplifying their capabilities in this domain.
              </p>
              <p className="pt-3 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left md:pr-[222px] pr-[100px]">
                our experts are here to supplement your resources and amplify
                your capabilities.
              </p>
            </div>

            <div className="mt-2 flex absolute bottom-0 right-0 left-56 top-96 md:left-auto md:right-0 md:top-[330px] 2xl:top-60 py-4 justify-center md:justify-end">
              <div className="w-28 md:w-72 h-28 md:h-72 bg-[#D22217] rotate-45 rounded-3xl"></div>
              <img
                src={require("../Assets/ezgif 2.png")}
                alt=""
                className="w-24 md:w-[170px] h-24 md:h-[170px] absolute md:left-16 md:right-0 md:top-0"
              />
            </div>
          </div>
        </div>
        <div className="md:mt-10 mt-11 flex flex-col md:flex-row md:gap-10 gap-6">
          <div className="h-auto md:w-1/2 rounded overflow-hidden  bg-[#07111A] relative text-white">
            <div className="mx-6 mb-6 md:mb-32">
              <h2 className="pt-6 md:text-desktop_bodytext_16 text-mobile_bodytext_14 font-body text-left">
                03
              </h2>
              <h1 className="pt-3 md:text-desktop_header_32 font-sans font-medium text-left md:pr-[89px] text-mobile_header_24 capitalize">
                Twilio Flex Plugin Licensing - AI Response Aid for Agents
              </h1>
              <p className="pt-4 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left capitalize">
                Our Twilio Flex Plugin Licensing offers AI response aid as a
                component of Twilio Flex. This ready-to-go package includes a UI
                Plugin, two to three Serverless Functions, and is 100% Twilio
                Native. It empowers your agents to deliver exceptional customer
                support with the assistance of AI technology.
              </p>
              <p className="pt-3 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left md:pr-[222px] pr-[100px]">
                Transforming Customer Support with Human-AI Interaction
              </p>
            </div>

            <div className="mt-2 flex absolute bottom-0 right-0 left-56 top-96 md:left-auto md:right-0 md:top-[330px] 2xl:top-60 py-4 justify-center md:justify-end">
              <div className="w-28 md:w-72 h-28 md:h-72 bg-primary-500 rotate-45 rounded-3xl"></div>
              <img
                src={require("../Assets/ezgif 3.png")}
                alt=""
                className="w-24 md:w-[170px] h-24 md:h-[170px] absolute md:left-16 md:right-0 md:top-0"
              />
            </div>
          </div>

          <div className="h-auto md:w-1/2 rounded overflow-hidden bg-[#F1F1BE] relative text-black-500">
            <div className="mx-6 mb-6 md:mb-0">
              <h2 className="pt-6 md:text-desktop_bodytext_16 text-mobile_bodytext_14 font-body text-left">
                04
              </h2>
              <h1 className="pt-3 md:text-desktop_header_32 font-sans font-medium text-left md:pr-[89px] text-mobile_header_24 capitalize">
                Customer Experience as a Service (CEaaS)
              </h1>
              <p className="pt-4 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left capitalize">
                Experience excellence in customer service with our comprehensive
                Customer Experience as a Service solution. We provide a trained
                staff proficient in handling various communication channels,
                including company phone lines, WhatsApp, WebChat, SMS, Voice,
                and Email.
              </p>
              <p className="pt-3 md:text-desktop_bodytext_18 text-mobile_bodytext_16 font-body font-normal text-left md:pr-[222px] pr-[100px]">
                Driving Excellence
              </p>
            </div>

            <div className="mt-2 flex absolute bottom-0 right-0 left-56 top-96 md:left-auto md:right-0 md:top-[330px] 2xl:top-60 py-4 justify-center md:justify-end">
              <div className="w-28 md:w-72 h-28 md:h-72  bg-primary-500 rotate-45 rounded-3xl"></div>
              <img
                src={require("../Assets/ezgif 4.png")}
                alt=""
                className="w-24 md:w-[170px] h-24 md:h-[170px] absolute md:left-16 md:right-0 md:top-0"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product_Card;
