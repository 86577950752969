import React, { useState } from "react";
import Modal from "../Components/Utl/Modal";

const StaffsGallery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="px-8 md:px-20 bg-white dark:bg-bg_black pt-16 md:pt-0 capitalize">
        <div className="flex flex-col md:flex-row md:gap-10 gap-6">
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg"
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg "
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg"
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-10 gap-6 md:mt-10 mt-6">
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg"
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg"
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
          <div
            className="md:w-1/3 bg-[#F5F5F5] py-6 md:px-6 px-8 rounded-lg"
            onClick={openModal}
          >
            <img
              src={require("../Assets/Rectangle 3844.png")}
              alt=""
              className="round"
            />
            <h1 className=" font-sans md:text-desktop_header_32 mt-6 text-mobile_header_24">
              chinaza smart
            </h1>
            <h2 className=" font-body md:text-desktop_bodytext_20 md:mt-3 text-mobile_bodytext_16 mt-2">
              customer care service
            </h2>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={closeModal} />
    </>
  );
};

export default StaffsGallery;
