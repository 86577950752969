import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" rounded md:w-8/12 w-full">
      <div
        className="flex items-center justify-between py-2 bg-black-500 cursor-pointer"
        onClick={toggleAccordion}
      >
        <span className="md:text-desktop_bodytext_20 text-mobile_bodytext_16">
          {title}
        </span>
        <svg
          className={`h-6 w-6 transform ${isOpen ? "rotate-180" : "rotate-0"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="md:mt-3 md:text-desktop_bodytext_18 text-mobile_bodytext_16 bg-black-500">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
