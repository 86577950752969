import "./App.css";
import ThemeContextWrapper from "./Context/ThemeContextWrapper";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CXaaS from "./Pages/CXaaS";
import Home from "./Pages/Home";
import { Consulting } from "./Pages/Consulting";
import { ContctUs } from "./Pages/ContctUs";
import { Staffs } from "./Pages/Staffs";

function App() {
  return (
    <ThemeContextWrapper>
      <div className="grid">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/CXaas" element={<CXaaS />} />
            <Route exact path="/Consulting" element={<Consulting />} />
            <Route exact path="/Contact-Us" element={<ContctUs />} />
            <Route exact path="/Staffs" element={<Staffs />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeContextWrapper>
  );
}

export default App;
