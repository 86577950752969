import React from "react";
import { Navbar } from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import Hero from "../Components/Utl/Hero";
import Banner from "../Components/Utl/Banner";
import Button from "../Components/Utl/Button";
import StaffsGallery from "../Sections/StaffsGallery";

export const Staffs = () => {
  return (
    <>
      <Navbar />
      <Banner
        bg_link={require("../Assets/banner-bg-2.jpeg")}
        heading={<span>hala tech consulting service</span>}
        description={
          "We are dedicated to providing expert guidance and support in the ever-evolving field of AI and Twilio/Twilio Flex CPaaS"
        }
        button={
          <Button
            text={"Contact Us"}
            link={"/Contact-Us"}
            md_width={"md:w-[250px]"}
            width={"w-11/12"}
            height={"h-14"}
            md_height={"md:h-[72px]"}
          />
        }
        p_top={"pt-48"}
      />
      <div className="md:flex md:pb-14 px-8 md:px-20 bg-white dark:bg-bg_black md:pt-16 pt-6">
        <div className="md:w-2/3 md:pr-40">
          <h1 className=" text-mobile_header_24 md:text-desktop_header_32 font-semibold font-body  text-black-300 dark:text-[#9D9D9D] capitalize">
            our staffs
          </h1>
          <p className="text-mobile_header_24 md:text-desktop_header_36 font-normal font-sans text-black-500 dark:text-white  capitalize md:mt-4 ">
            the exceptional staffs working with us to make your business better
          </p>
        </div>
        <div className="md:w-1/3 md:my-auto mt-5 bg-[#F5F5F5]">
          <div class="max-w-md mx-auto ">
            <div class="relative flex items-center w-full h-12 rounded-lg overflow-hidden">
              <div class="grid place-items-center h-full w-12 text-gray-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>

              <input
                class=" h-full w-full outline-none text-sm text-gray-700 pr-2 bg-[#F5F5F5]"
                type="text"
                placeholder="Search Telents"
              />
            </div>
          </div>
        </div>
      </div>
      <StaffsGallery />
      <Hero
        tittle={"Discover the Power of AI with Hala Tech AI"}
        description={
          " we provide comprehensive support throughout your AI journey. Partner with us and unlock the potential of AI to transform your business. Contact us today to unlock the power of AI and revolutionize your business."
        }
        text_color={"text-white"}
        img_link={require("../Assets/Frame 25.png")}
        background={"bg-primary-700"}
        button={
          <Button
            text={"Contact Us"}
            link={"/Contact-Us"}
            md_width={"md:w-[362px]"}
            width={"w-60"}
            background="bg-white"
            text_color="text-primary-500"
          />
        }
      />
      <Footer />
    </>
  );
};
