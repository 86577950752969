import React from "react";
import Button from "./Button";

const Banner = ({
  slogan,
  heading,
  description,
  button,
  partner_section,
  bg_link,
  p_top,
}) => {
  return (
    <>
      <section
        className={`relative bg-cover bg-center bg-no-repeat px-8 md:px-20 ${p_top}`}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bg_link})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-transparent to-transparent w-auto"></div>
        <div className="relative">
          {" "}
          {/* Add 'relative' class to make the button position relative */}
          <div className="text-center">
            <h2 className="text-white text-mobile_bodytext_20 capitalize">
              {slogan}
            </h2>
            <h1 className="text-white text-mobile_header_36 font-medium capitalize pt-3">
              {heading}
            </h1>
            <h2 className="text-white text-mobile_bodytext_14 font-light capitalize pt-4">
              {description}
            </h2>
            <div className="justify-center pt-6 z-10">{button}</div>
            <div className="flex justify-center pt-20 pb-16">
              {partner_section}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
