import React from "react";

const Card = ({
  icon,
  icon_bg_color,
  Tittle,
  Description,
  icon_w = "w-12",
  icon_h = "h-12",
  icon_md_w = "md:w-16",
  icon_md_h = "md:h-16",
  bg_color = "bg-white",
  text_color = "text-white",
}) => {
  return (
    <div className="">
      <div
        className={`flex h-full ${bg_color} p-6 flex-col gap-4  2xl:w-auto w-auto rounded ${text_color}`}
      >
        <div className="flex flex-col">
          <div
            className={`${icon_w} ${icon_h} ${icon_md_h} ${icon_md_w} inline-flex items-center justify-center ${icon_bg_color} text-white flex-shrink-0`}
          >
            {icon}
          </div>
        </div>
        <div>
          <h2 className="text-mobile_bodytext_20 md:text-desktop_bodytext_24 font-medium capitalize">
            {Tittle}
          </h2>
        </div>
        <div className="flex-grow">
          <p className=" text-mobile_bodytext_16 md:text-desktop_bodytext_18 capitalize">
            {Description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
