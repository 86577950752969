import React from "react";
import Button from "../Components/Utl/Button";

const Footer = () => {
  return (
    <footer className=" bg-bg_black  px-8 md:px-20 font-body">
      <div className="py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="md:w-1/4 md:text-left text-white md:mr-32">
          <a className="flex title-font font-medium items-center md:justify-start ">
            <span className="sr-only">Home</span>
            <img
              src={require("../Assets/Logo.png")}
              className=" w-[64px] h-[24.976px] md:w-[150px] md:h-[58.537px]"
            />
          </a>
          <p className="mt-2 text-sm pr-5 text-mobile_bodytext_16 md:text-desktop_bodytext_18">
            we provide comprehensive support throughout your AI journey. Partner
            with us and unlock the potential of AI to transform your business.
          </p>
        </div>
        <div className="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 text-left">
          <div className="lg:w-1/3 md:w-1/2 w-full">
            <h2 className="text-white mb-3 text-mobile_bodytext_20 md:text-desktop_bodytext_16">
              CATEGORIES
            </h2>
            <nav className="list-none mb-10 text-mobile_bodytext_16 md:text-desktop_bodytext_16">
              <li>
                <a className="text-gray-400 hover:text-white">First Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Second Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Third Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Fourth Link</a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full">
            <h2 className="text-white mb-3 text-mobile_bodytext_20 md:text-desktop_bodytext_16">
              CATEGORIES
            </h2>
            <nav className="list-none mb-10 text-mobile_bodytext_16 md:text-desktop_bodytext_16">
              <li>
                <a className="text-gray-400 hover:text-white">First Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Second Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Third Link</a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Fourth Link</a>
              </li>
            </nav>
          </div>
          <div className="md:w-1/3 w-full">
            <div className="w-11/12 md:w-auto">
              <h2 className="text-white mb-3 capitalize text-mobile_bodytext_16 md:text-desktop_bodytext_20">
                subscribe to our newsletter
              </h2>
              <div className="flex lg:flex-wrap flex-wrap justify-start items-end md:justify-start">
                <div className="w-full">
                  <input
                    type="text"
                    id="footer-field"
                    name="footer-field"
                    placeholder="Enter Email Address"
                    className="w-full bg-white rounded border border-gray-700 focus:ring-2 focus:ring-indigo-900 focus:border-indigo-500 text-base outline-none text-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="mt-4">
                  <Button
                    text={"Contact Us"}
                    link={"/"}
                    width={"w-[222px]"}
                    height={"h-[44px]"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="mx-auto py-4 md:px-60 flex flex-wrap flex-col sm:flex-row ">
          <div className="text-white flex gap-9 text-mobile_bodytext_14">
            <a href="/" target="_blank" rel="noopener noreferrer">
              Terms
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              Privacy
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              Cookies
            </a>
          </div>
          <span className="inline-flex sm:ml-auto mt-10 md:mt-0 md:justify-center sm:justify-start ">
            <a className="text-white">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-white">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-white">
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
