import React, { useState } from "react";
import Button from "../Components/Utl/Button";
import ThemeSwitch from "../Components/ThemeSwitch";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="sticky top-0 z-50 bg-bg_black md:gap-10 gap-6 px-8 md:px-20 md:h-20">
      <div className="py-5 md:py-2">
        <div className="flex items-center justify-between h-8 md:h-auto">
          <div className="md:flex md:items-center md:gap-12">
            <a className="block" href="/">
              <span className="sr-only">Home</span>
              <img
                src={require("../Assets/Logo.png")}
                className=" w-[64px] h-[24.976px] md:w-[150px] md:h-[58.537px]"
              />
            </a>
          </div>

          <div className="hidden md:block">
            <nav>
              <ul className="flex items-center md:gap-12 ">
                <li>
                  <a
                    className="text-white transition hover:text-yellow-500/75 text-desktop_buttontext_24 font-normal capitalize"
                    href="/CXaas"
                  >
                    CXaaS
                  </a>
                </li>
                <li>
                  <a
                    className="text-white transition hover:text-yellow-500/75 text-desktop_buttontext_24 font-normal capitalize"
                    href="/Consulting"
                  >
                    Consulting
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="flex gap-3">
            <div className="md:block hidden">
              <Button
                text={"Contact Us"}
                link={"/Contact-Us"}
                width={"w-[177px]"}
              />
            </div>
            <div className="my-auto order-last ">
              <ThemeSwitch />
            </div>

            <div className="block md:hidden">
              <button
                onClick={toggleNavbar}
                type="button"
                className="text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  {isOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="md:hidden">
            <nav className="pb-4">
              <ul className="flex flex-col justify-center">
                <li className="text-center">
                  <a
                    className="text-white transition hover:text-yellow-500/75 text-mobile_buttontext_20 font-normal capitalize"
                    href="/CXaas"
                  >
                    CXaaS
                  </a>
                </li>
                <li className="text-center">
                  <a
                    className="text-white transition hover:text-yellow-500/75 text-mobile_buttontext_20 font-normal capitalize"
                    href="/Consulting"
                  >
                    Consulting
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </header>
  );
};
