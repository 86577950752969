import React from "react";

const Button = ({
  link,
  text,
  width,
  md_width,
  height,
  md_height,
  background = "bg-primary-500",
  text_color = "text-white",
}) => {
  return (
    <a
      className={`text-mobile_buttontext_20 md:text-desktop_buttontext_24 font-medium  capitalize font-body rounded py-4 px-[10px] justify-center align-middle mx-auto my-auto ${width} ${md_width} ${height} ${md_height} ${background} ${text_color} flex items-center`}
      href={link}
    >
      {text}
    </a>
  );
};

export default Button;
