import React from "react";
import Accordion from "../Components/Utl/Accordion";

const FAQ = () => {
  const data = [
    {
      title:
        "Is SmartDeployer easy to use, even for those without coding experience?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Massa fringilla id facilisis volutpat in. Maecenas lectus lorem neque lacus sed neque. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.",
    },
    {
      title:
        "Is SmartDeployer easy to use, even for those without coding experience?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Massa fringilla id facilisis volutpat in. Maecenas lectus lorem neque lacus sed neque. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.",
    },
    {
      title:
        "Is SmartDeployer easy to use, even for those without coding experience?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Massa fringilla id facilisis volutpat in. Maecenas lectus lorem neque lacus sed neque. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.",
    },
    {
      title:
        "Is SmartDeployer easy to use, even for those without coding experience?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Massa fringilla id facilisis volutpat in. Maecenas lectus lorem neque lacus sed neque. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.",
    },
    {
      title:
        "Is SmartDeployer easy to use, even for those without coding experience?",
      content:
        "Lorem ipsum dolor sit amet consectetur. Massa fringilla id facilisis volutpat in. Maecenas lectus lorem neque lacus sed neque. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.. Iaculis malesuada ut convallis purus nisi adipiscing ac. Lectus vel egestas pretium interdum. Urna.",
    },
  ];
  const bg_link = require("../Assets/banner-bg-3.png");
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bg_link})`,
        backgroundSize: "cover",
      }}
      className="flex flex-col px-8 md:px-20 pt-16 pb-16 items-center align-middle gap-6 bg-cover bg-center bg-no-repeat text-white"
    >
      <div className="flex flex-col items-center align-middle md:pb-10">
        <h1 className="text-mobile_header_24 font-sans md:mb-3 ">
          Frequently Asked Questions
        </h1>
        <h2 className="text-mobile_bodytext_20 ">
          Basic Things You Need to Know About Hala tech Ai
        </h2>
      </div>
      {Object.values(data).map(({ title, content }, index) => {
        return <Accordion title={title} content={content} />;
      })}
    </div>
  );
};

export default FAQ;
