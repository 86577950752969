import React from "react";
import { Navbar } from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import Hero from "../Components/Utl/Hero";
import Banner from "../Components/Utl/Banner";
import Button from "../Components/Utl/Button";
import ContactForm from "../Sections/ContactUsForm";

export const ContctUs = () => {
  return (
    <>
      <Navbar />
      <Banner
        bg_link={require("../Assets/banner-bg-2.jpeg")}
        heading={<span>hala tech consulting service</span>}
        description={
          "We are dedicated to providing expert guidance and support in the ever-evolving field of AI and Twilio/Twilio Flex CPaaS"
        }
        button={
          <Button
            text={"Contact Us"}
            link={"/"}
            md_width={"md:w-[250px]"}
            width={"w-11/12"}
            height={"h-14"}
            md_height={"md:h-[72px]"}
          />
        }
        p_top={"pt-48"}
      />
      <div className="flex flex-col md:flex-row md:gap-5 gap-10 px-8 md:px-20 pt-6  dark:bg-bg_black bg-bg_green md:pt-20 md:pb-20">
        <div className="md:w-1/2 bg-primary-800 md:pt-10 pt-8 md:px-10 px-8 text-white rounded-lg md:h-2/3 overflow-hidden relative pb-40">
          <h1 className=" md:text-desktop_header_32 text-mobile_header_24 font-bold md:pb-4 pb-6">
            Contact Information
          </h1>
          <p className="md:text-desktop_bodytext_18 md:pb-12 text-mobile_bodytext_20 pb-8">
            Wherever you are in your digital transformation journey, we’d love
            to talk to you to understand your challenges.
          </p>
          <div className="flex flex-col gap-5">
            <div className="flex gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.0002 10.999H22.0002C22.0002 5.869 18.1272 2 12.9902 2V4C17.0522 4 20.0002 6.943 20.0002 10.999Z"
                  fill="white"
                />
                <path
                  d="M13.0003 8C15.1033 8 16.0003 8.897 16.0003 11H18.0003C18.0003 7.775 16.2253 6 13.0003 6V8ZM16.4223 13.443C16.2301 13.2684 15.9776 13.1752 15.7181 13.1832C15.4585 13.1913 15.2123 13.2998 15.0313 13.486L12.6383 15.947C12.0623 15.837 10.9043 15.476 9.71228 14.287C8.52028 13.094 8.15928 11.933 8.05228 11.361L10.5113 8.967C10.6977 8.78613 10.8064 8.53982 10.8144 8.28021C10.8225 8.02059 10.7292 7.76804 10.5543 7.576L6.85928 3.513C6.68432 3.32036 6.44116 3.20351 6.18143 3.18726C5.92171 3.17102 5.66588 3.25666 5.46828 3.426L3.29828 5.287C3.12539 5.46052 3.0222 5.69145 3.00828 5.936C2.99328 6.186 2.70728 12.108 7.29928 16.702C11.3053 20.707 16.3233 21 17.7053 21C17.9073 21 18.0313 20.994 18.0643 20.992C18.3088 20.9783 18.5396 20.8747 18.7123 20.701L20.5723 18.53C20.7417 18.3325 20.8276 18.0768 20.8115 17.8171C20.7954 17.5573 20.6788 17.3141 20.4863 17.139L16.4223 13.443Z"
                  fill="white"
                />
              </svg>
              <h1 className="md:text-desktop_bodytext_18 text-mobile_bodytext_16">
                +1012 3456 789
              </h1>
            </div>
            <div className="flex gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                  fill="white"
                />
              </svg>
              <h1 className="md:text-desktop_bodytext_18 text-mobile_bodytext_20">
                demo@gmail.com
              </h1>
            </div>
            <div className="flex gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z"
                  fill="white"
                />
              </svg>
              <h1 className="md:text-desktop_bodytext_18 md:mr-60 md:mt-2">
                132 Dartmouth Street Boston, Massachusetts 02156 United States
              </h1>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 bg-white border rounded-lg">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </>
  );
};
