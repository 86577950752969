import React from "react";
import { Navbar } from "../Sections/Navbar";
import Banner from "../Components/Utl/Banner";
import Button from "../Components/Utl/Button";
import Our_Channels from "../Sections/Our_Channels";
import Footer from "../Sections/Footer";
import Hero from "../Components/Utl/Hero";
import Testimonies from "../Sections/Testimonies";

const CXaaS = () => {
  return (
    <>
      <Navbar />
      <Banner
        bg_link={require("../Assets/banner-bg-1.png")}
        heading={
          <span>
            Enhancing Customer Experiences with <br />
            <span className=" bg-gradient-to-r from-[#8AC540] to-[#26C5F3] text-transparent bg-clip-text">
              {" "}
              AI-Powered Support
            </span>
          </span>
        }
        description={
          "Our comprehensive solution is designed to transform your customer support operations, driving enhanced responsiveness, efficiency, and satisfaction."
        }
        button={
          <Button
            text={"Contact Us"}
            link={"/Contact-Us"}
            md_width={"md:w-[250px]"}
            width={"w-11/12"}
            height={"h-14"}
            md_height={"md:h-[72px]"}
          />
        }
        p_top={"pt-32"}
      />
      <div className="flex flex-col md:flex-row md:gap-12 gap-6 px-8 md:px-20 md:py-16 bg-white dark:bg-bg_black 2xl:w-auto py-6 capitalize">
        <div className="md:w-1/2 md:text-desktop_header_36 font-semibold font-sans dark:text-white text-black-500 text-mobile_header_24">
          <h1>Transforming Customer Support with Human-AI Interaction</h1>
        </div>
        <div className="md:w-1/2 md:text-desktop_bodytext_18 font-normal font-body dark:text-white text-black-400 text-mobile_bodytext_16">
          <p>
            In today's rapidly evolving business landscape, providing
            exceptional customer experiences is paramount. Hala Tech AI
            understands the challenges faced by enterprises in managing multiple
            communication channels while striving to deliver personalized and
            efficient support. Our CEaaS platform is the answer, blending the
            power of artificial intelligence with human interaction to create an
            unparalleled customer support experience.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-12 px-8 md:px-20 md:py-16 bg-white dark:bg-bg_black 2xl:w-auto py-6 capitalize">
        <div className="md:w-1/2">
          <h1 className="md:text-desktop_header_32 font-medium font-sans md:pb-8 pb-5 dark:text-white text-black-500 text-mobile_header_24">
            key features
          </h1>
          <h1 className="md:text-desktop_header_32 font-medium font-sans md:pb-4 pb-6 dark:text-white text-black-500 text-mobile_header_24">
            Trained Staff for Multichannel Communication
          </h1>
          <p className=" md:text-desktop_bodytext_18 font-medium font-body dark:text-white text-black-400 text-mobile_bodytext_16">
            We understand that effective communication is the cornerstone of
            exceptional customer experiences. That's why our Cxaas solution
            provides you with a team of highly trained professionals who
            specialize in handling various communication channels. Whether it's
            company phone lines, WhatsApp, WebChat, SMS, Voice, or Email, our
            skilled staff is equipped to deliver exceptional service across all
            channels.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            className=""
            alt="hero"
            src={require("../Assets/Rectangle 19.png")}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-12 px-8 md:px-20 md:py-16 bg-white dark:bg-bg_black 2xl:w-auto py-6 capitalize">
        <div className="md:w-1/2">
          <h1 className="md:text-desktop_header_32 font-medium font-sans md:pb-4 pb-6 dark:text-white text-black-500 text-mobile_header_24">
            AI Frontline Support for Enhanced Responsiveness
          </h1>
          <p className=" md:text-desktop_bodytext_18 font-medium font-body dark:text-white text-black-400 text-mobile_bodytext_16">
            In today's fast-paced world, customers expect quick and accurate
            responses. Our Cxaas solution integrates cutting-edge AI technology
            to augment your customer support team. By leveraging AI-powered bots
            and virtual assistants, we enhance the responsiveness of your
            frontline support. Our AI bots are trained to understand and respond
            to customer queries, providing instant assistance and freeing up
            your human agents to focus on more complex tasks.
          </p>
        </div>
        <div className="md:w-1/2 order-first">
          <img
            className=""
            alt="hero"
            src={require("../Assets/Rectangle 20.png")}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-12 px-8 md:px-20 md:py-16 bg-white dark:bg-bg_black 2xl:w-auto py-6 capitalize">
        <div className="md:w-1/2">
          <h1 className="md:text-desktop_header_32 font-medium font-sans md:pb-4 pb-6 dark:text-white text-black-500 text-mobile_header_24">
            Client Data Management Services
          </h1>
          <p className=" md:text-desktop_bodytext_18 font-medium font-body dark:text-white text-black-400 text-mobile_bodytext_16">
            Preparing your company for AI integration is crucial for seamless
            operations. At Hala Tech AI, we offer comprehensive client data
            management services to ensure a smooth transition. Our experts
            analyze and organize your data, making it ready for integration with
            AI systems. This enables you to leverage the power of AI-driven
            insights and analytics, leading to better decision-making and
            customer engagement.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            className=""
            alt="hero"
            src={require("../Assets/Rectangle 21.png")}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:gap-12 gap-6 px-8 md:px-20 md:py-16 bg-white dark:bg-bg_black 2xl:w-auto py-6 capitalize">
        <div className="md:w-1/2">
          <h1 className="md:text-desktop_header_32 font-medium font-sans md:pb-4 pb-6 dark:text-white text-black-500 text-mobile_header_24">
            Informative Dashboards and Robust Admin Panel
          </h1>
          <p className="  md:text-desktop_bodytext_18 font-medium font-body dark:text-white text-black-400 text-mobile_bodytext_16">
            Managing and tracking customer interactions and transaction history
            is made effortless with our informative dashboards. Gain valuable
            insights into customer behavior, preferences, and trends with
            easy-to-understand visualizations. Additionally, our robust admin
            panel allows for streamlined configuration, empowering you to tailor
            the Cxaas solution to your specific business needs.
          </p>
        </div>
        <div className="md:w-1/2 order-first">
          <img
            className=""
            alt="hero"
            src={require("../Assets/Rectangle 22.png")}
          />
        </div>
      </div>
      <Our_Channels />
      <Testimonies />

      <Hero
        tittle={"Discover the Power of AI with Hala Tech AI"}
        description={
          " we provide comprehensive support throughout your AI journey. Partner with us and unlock the potential of AI to transform your business. Contact us today to unlock the power of AI and revolutionize your business."
        }
        text_color={"text-white"}
        img_link={require("../Assets/Frame 25.png")}
        background={"bg-primary-700"}
        
        button={
          <Button
            text={"Contact Us"}
            link={"/Contact-Us"}
            md_width={"md:w-[362px]"}
            width={"w-60"}
            background="bg-white"
            text_color="text-primary-500"
          />
        }
      />
      <Footer />
    </>
  );
};

export default CXaaS;
