import React from "react";
import Button from "../../Components/Utl/Button";

const Hero = ({
  tittle,
  subtittle,
  description,
  img_link,
  button,
  img_order,
  background,
  text_color = "dark:text-white",
}) => {
  console.log(img_link);
  return (
    <section className="px-8 md:px-20 bg-white dark:bg-bg_black 2xl:w-auto py-6 md:py-16 capitalize">
      <div
        className={`mx-auto flex md:flex-row flex-col items-center gap-9 ${background}  ${text_color}`}
      >
        <div
          className={`md:w-1/2 flex flex-col items-start text-left md:pt-16 pt-6 md:pl-8 px-5 md:px-0`}
        >
          <h1 className=" text-mobile_header_24 font-medium font-sans">
            {tittle}
          </h1>
          <h2 className="pt-2 text-mobile_bodytext_16 font-medium font-body">
            {subtittle}
          </h2>
          <p className="pt-4 text-mobile_bodytext_16 font-normal font-body">
            {description}
          </p>
          <div className="flex justify-start items-start mt-10">{button}</div>
        </div>
        <div className={`md:w-1/2  md:mt-0 2xl:px-40  ${img_order}`}>
          <img className="" alt="hero" src={img_link} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
